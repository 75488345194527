import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from "../config";
import Footer from '../components/Footer';
import Header from '../components/Header';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, { Navigation, Autoplay, Pagination, Scrollbar, A11y } from 'swiper';

import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

export default function News({ userData, clearUserData }) {
  const { id } = useParams();
  const [newsData, setNewsData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  useEffect(() => {
    AOS.refresh();
  });

  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "news"));
        const data = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNewsData(data);

        const selectedIndex = data.findIndex(item => item.id === id);
        if (selectedIndex !== -1) {
          setActiveIndex(selectedIndex);
          updateMetaTags(data[selectedIndex]); // Update meta tags when the data is available
        }
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNewsData();
  }, [id]);

  const updateMetaTags = (newsItem) => {
    if (newsItem) {
      document.title = newsItem.title || "The Fortress News";
      
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute("content", newsItem.content || "The Fortress News");
      }

      const ogTitle = document.querySelector('meta[property="og:title"]');
      if (ogTitle) {
        ogTitle.setAttribute("content", newsItem.title || "The Fortress News");
      }

      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogDescription) {
        ogDescription.setAttribute("content", (newsItem.content || "").substring(0, 150));
      }

      const ogImage = document.querySelector('meta[property="og:image"]');
      if (ogImage) {
        ogImage.setAttribute("content", newsItem.image || "https://firebasestorage.googleapis.com/v0/b/thefortresswebsite-d5c43.appspot.com/o/misc%2Flogo.jpg?alt=media&token=5186a951-e149-469e-a2c3-49aa091bb5f1");
      }

      const ogUrl = document.querySelector('meta[property="og:url"]');
      if (ogUrl) {
        ogUrl.setAttribute("content", `https://thefortressph.org/news/${newsItem.id}`);
      }

      const ogType = document.querySelector('meta[property="og:type"]');
      if (ogType) {
        ogType.setAttribute("content", "article");
      }
    }
  };

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.realIndex);
    AOS.refresh(); 
  };

  const newsItem = newsData[activeIndex];
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(date);
  };

  return (
    <>
    {newsItem && (
  <Helmet>
    <title>{newsItem.title || "The Fortress News"}</title>
    <meta
      name="description"
      content={newsItem.content ? newsItem.content.toString() : "The Fortress News"}
    />
    <meta property="og:title" content={newsItem.title || "The Fortress News"} />
    <meta
      property="og:description"
      content={newsItem.content ? newsItem.content.toString().substring(0, 150) : ""}
    />
    <meta
      property="og:image"
      content={
        newsItem.image ||
        "https://firebasestorage.googleapis.com/v0/b/thefortresswebsite-d5c43.appspot.com/o/misc%2Flogo.jpg?alt=media&token=5186a951-e149-469e-a2c3-49aa091bb5f1"
      }
    />
    <meta property="og:url" content={`https://thefortressph.org/news/${newsItem.id}`} />
    <meta property="og:type" content="article" />
  </Helmet>
)}

      <Header userData={userData} clearUserData={clearUserData} />
      <div>
        <div className="page-title" data-aos="fade">
          <div className="container d-lg-flex justify-content-between align-items-center">
            <h1 className="mb-2 mb-lg-0">Details</h1>
            <nav className="breadcrumbs">
              <ol>
                <li><a href="/home">Home</a></li>
                <li className="current">News</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <section id="portfolio-details" className="portfolio-details section">
        <div className="main-div-container container" data-aos="fade-up">
          {newsData.length > 0 && (
            <Swiper
              loop={true}
              speed={600}
              pagination={{ clickable: true }}
              navigation
              className="news-swiper"
              spaceBetween={50}
              onSlideChange={handleSlideChange}
            >
              {newsData.map((item, index) => (
                <SwiperSlide key={index}>
                  <img src={item.image} alt={`News ${index}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>

        {newsItem && (
          <div className="portfolio-description-container container row justify-content-between gy-4 mt-4">
            <div className="col-lg-8" data-aos="fade-up" data-aos-delay="150">
              <div className="portfolio-description">
                <h2>{newsItem.title}</h2>
                <p>{newsItem.content}</p>
              </div>
            </div>

            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100">
              <div className="portfolio-info">
                <h3>Details</h3>
                <ul>
                  <li><strong>Date</strong> {formatDate(newsItem.date)}</li>
                  <li><strong>Post URL</strong> <a href={`${newsItem.postUrl}`}>{`${newsItem.postUrl}`}</a></li>
                  <li><a href={`${newsItem.postUrl}`} className="btn-visit align-self-start">Visit Facebook Post</a></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </>
  );
}
