import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Typed from 'typed.js';
import Loader from "../pages/Loader";
import { collection, getDocs, doc, getDoc} from 'firebase/firestore';
import { db } from "../config";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";


export default function Home({ userData, clearUserData }) {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  useEffect(() => {
      AOS.refresh();
  });


  const [recentWinTitle, setRecentWinTitle] = useState('');
  const [recentWinName, setRecentWinName] = useState('');

  useEffect(() => {
    const fetchRecentWinTitle = async () => {
      try {
        const docRef = doc(db, 'recent_news_title', 'l3lPf1GlmJ4StnTSlh6E');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setRecentWinTitle(data.title);
          setRecentWinName(data.name);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching recent win title: ', error);
      }
    };

    fetchRecentWinTitle();
  }, []);

  useEffect(() => {
    if (recentWinTitle && recentWinName) {
      const options = {
        strings: [recentWinTitle, recentWinName],
        typeSpeed: 100,
        backSpeed: 105,
        loop: true,
      };
      const typed = new Typed(".input", options);

      return () => {
        typed.destroy();
      };
    }
  }, [recentWinTitle, recentWinName]);


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500); 

    return () => clearTimeout(timer);
  }, []); 

  useEffect(() => {
    const hideTimer = setTimeout(() => {
      const preloader = document.getElementById('preloader');
      if (preloader) {
        preloader.classList.add('hidden');
      }
    }, 1000);

    return () => clearTimeout(hideTimer);
  }, []); 

  const [wins, setWins] = useState([]);
  const [news, setNews] = useState([]);
  const [latestWin, setLatestWin] = useState(null);

  useEffect(() => {
    const fetchWins = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "wins"));
        const winsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })).sort((a, b) => new Date(b.date) - new Date(a.date)); 
        setWins(winsData.slice(1));
        if (winsData.length > 0) {
          setLatestWin(winsData[0]); 
        }
      } catch (error) {
        console.error("Error fetching wins:", error);
      }
    };
    fetchWins();
  }, []);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "news"));
        const newsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setNews(newsData);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };
   
    fetchNews();
  }, []);

  useEffect(() => {
    const slider = document.querySelector('.news-container .slider');
    const btnLeft = document.getElementById('prev_btn');
    const btnRight = document.getElementById('next_btn');

    let deleteInterval;

    const updateSliderPositions = () => {
      const sliderItems = document.querySelectorAll('.news-container .slider .slider-item');
      sliderItems.forEach((item, index) => {
        item.style.left = `calc((300px + 20px) * ${index})`;
      });
    };

    const nextSlide = () => {
      if (slider) {
        slider.appendChild(slider.firstElementChild);
        updateSliderPositions();
      }
    };

    const prevSlide = () => {
      if (slider) {
        slider.prepend(slider.lastElementChild);
        updateSliderPositions();
      }
    };

    const autoSlide = () => {
      deleteInterval = setInterval(() => {
        nextSlide();
      }, 4000);
    };

    const deleteAutoSliding = () => {
      clearInterval(deleteInterval);
    };

    if (btnRight && btnLeft && slider) {
      btnRight.addEventListener('click', nextSlide);
      btnLeft.addEventListener('click', prevSlide);
      slider.addEventListener('mouseover', deleteAutoSliding);
      btnRight.addEventListener('mouseover', deleteAutoSliding);
      btnLeft.addEventListener('mouseover', deleteAutoSliding);
      slider.addEventListener('mouseout', autoSlide);
      btnRight.addEventListener('mouseout', autoSlide);
      btnLeft.addEventListener('mouseout', autoSlide);
      autoSlide();
    }

    updateSliderPositions();

    return () => {
      if (btnRight && btnLeft && slider) {
        btnRight.removeEventListener('click', nextSlide);
        btnLeft.removeEventListener('click', prevSlide);
        slider.removeEventListener('mouseover', deleteAutoSliding);
        btnRight.removeEventListener('mouseover', deleteAutoSliding);
        btnLeft.removeEventListener('mouseover', deleteAutoSliding);
        slider.removeEventListener('mouseout', autoSlide);
        btnRight.removeEventListener('mouseout', autoSlide);
        btnLeft.removeEventListener('mouseout', autoSlide);
      }
      deleteAutoSliding();
    };
  }, [news]);

  const handleSeeDetails = (id) => {
    navigate(`/news/${id}`);
  };

  return (
    <>
      {loading && <Loader />}
      <Helmet>
        {/* Open Graph Meta Tags */}
        <title>{latestWin?.title || "Default Title"}</title>
        <meta property="og:title" content={latestWin?.title || "Title"} />
        <meta
          property="og:description"
          content={latestWin?.content || "Description"}
        />
        <meta
          property="og:image"
          content={latestWin?.image || "https://firebasestorage.googleapis.com/v0/b/thefortresswebsite-d5c43.appspot.com/o/misc%2Flogo.jpg?alt=media&token=5186a951-e149-469e-a2c3-49aa091bb5f1"}
        />
        <meta
          property="og:url"
          content="https://thefortressph.org/home" // Replace with your website's URL
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header userData={userData} clearUserData={clearUserData} />

      <div>
        <section id="hero">
          <div className="hero home-main-container"></div>
        </section>

        <section className="WINS">
          {/* <video autoPlay muted loop className="video-background">
            <source src="/vids/bg.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video> */}
          <div className="News-header" data-aos="fade-up" data-aos-delay="100">
            <div className="News-title">
              <h1>Another Victory <span className="input">Congratulations</span></h1>
            </div>
            <div className="wrapper">
              <div className="win-container ">
                <div className="hero-pic">
                  <img src={latestWin?.image} alt="profile pic" />
                </div>
                <div className="News-text">
                  <p>{latestWin?.content}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section>
          <div>
            <h1 className="section-title">Recent <span>Wins</span></h1>
          </div>
          <div id="card-area" data-aos="fade-up" data-aos-delay="100">
            <div className="wrapper">
              <div className="box-area">
                {wins.map((item, index) => (
                  <div className="box" key={index}>
                    <img className='wins-image' alt="" src={item.image} />
                    <div className="overlay">
                      <h3 className='wins-content'>{item.title}</h3>
                      <a href={`/wins/${item.id}`}>See Details</a>  
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section id="news">
          <div>
            <h1 className="section-title"> <span>News</span></h1>
          </div>
          <div className="news-container" data-aos="fade-up" data-aos-delay="100">
            <header className="section-header">
             
              <div className="navigation-btns">
                <i className="fas fa-arrow-left" id="prev_btn"></i>
                <i className="fas fa-arrow-right" id="next_btn"></i>
              </div>
            </header>
            <div className="slider">
              {news.map((item, index) => (
                <div className="slider-item" key={index}>
                  <img src={item.image} alt="news" />
                  <div className="overlay">
                    <button 
                      className="btn explore-now-btn"
                      onClick={() => handleSeeDetails(item.id)}
                    >
                      See Details
                    </button>
                    <div className="destination-desc">
                      <h3>{item.title}</h3>
                      <p><i className="fa-solid fa-calendar"></i>{item.date}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        
      <section className="about-coaches">
      <h2>Meet the</h2>
      
      <span className="bg-watermark">Mentors</span>
      <div className="cards">
        <div className="card">
          <img src="/img/ARVIN VILLAGARCIA.png" alt="ARVIN VILLAGARCIA"/>
          <div className="card-content">
            <h3>Arvin Villagarcia, MLL</h3>
            <p>Head Coach</p>
          </div>
        </div>
        <div className="card">
          <img src="/img/ALIREZA P. BALON.png" alt="ALIREZA P. BALON"/>
          <div className="card-content">
            <h3>Alireza P. Balon</h3>
            <p>Resident Language Coach</p>
          </div>
        </div>
      </div>
    </section>


        <section id="contact">
          <Footer />
        </section>
      </div>
    </>
  );
}
